.pagination {
  display: flex;
  @include list-unstyled();
}

.page-link {
  position: relative;
  display: block;
  color: $pagination-color;
  background-color: $pagination-bg;
  border: $pagination-border-width solid $pagination-border-color;

  &:hover {
    z-index: 2;
    color: $pagination-hover-color;
    text-decoration: none;
    background-color: $pagination-hover-bg;
    border-color: $pagination-hover-border-color;
  }

  &:focus {
    z-index: 3;
    outline: $pagination-focus-outline;
    box-shadow: $pagination-focus-box-shadow;
  }
}

.page-item {
  &:not(:first-child) .page-link {
    margin-left: $pagination-margin-left;
  }

  &.active .page-link {
    z-index: 3;
    color: $pagination-active-color;
    background-color: $pagination-active-bg;
    border-color: $pagination-active-border-color;
  }

  &.disabled .page-link {
    color: $pagination-disabled-color;
    pointer-events: none;
    // Opinionated: remove the "hand" cursor set previously for .page-link
    cursor: auto;
    background-color: $pagination-disabled-bg;
    border-color: $pagination-disabled-border-color;
  }
}


//
// Sizing
//
@include pagination-size($pagination-padding-y, $pagination-padding-x, null, $pagination-line-height, $pagination-border-radius);

.pagination-lg {
  @include pagination-size($pagination-padding-y-lg, $pagination-padding-x-lg, $font-size-lg, $line-height-lg, $border-radius-lg);
}

.pagination-sm {
  @include pagination-size($pagination-padding-y-sm, $pagination-padding-x-sm, $font-size-sm, $line-height-sm, $border-radius-sm);
}
